<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Referral users</h4>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive" style="table-layout: fixed">
              <table class="table" v-if="!loading">
                <thead>
                  <tr class="px-3">
                    <th><strong>Information</strong></th>
                    <th><strong>Status</strong></th>
                    <th class="pr-3 text-center"><strong>Action</strong></th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                  <tr v-if="referralUsers.length > 0" v-for="item in referralUsers">
                    <td>
                      <div class="text-sm text-gray-900">
                        <b>Full name:</b> {{ item.full_name }}
                      </div>
                      <p><b>Email:</b>{{ item.email }}</p>
                    </td>

                    <td>
                       <span
                           class="badge"
                           v-bind:class="{
                            'badge-success': item.is_claimed,
                            'badge-danger': !item.is_claimed,
                          }"
                       >{{ item.is_claimed ? "Claimed" : "Not claimed" }}</span
                       >
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="claimReferredUser(item.id, item)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-check</v-icon>
                                </span>
                                <span class="navi-text">{{ item.is_claimed ? "Unclaim" : "Claim" }}</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="referralUsers.length == 0">
                    <td colspan="4" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="referralUsers.length > 0"
                  class="pull-right mt-7"
                  @input="getAllReferralUsers"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>

</template>

<script>
import UserService from "@/services/user/UserService";

const userService = new UserService();
export default {
  name: "ReferralUsers",
  props: ['userId'],
  data() {
    return {
      referralUsers: [],
      loading: false,
      search: {},
      total: null,
      perPage: null,
      page: null,
      lastPage: null,
    }
  },
  mounted() {
    this.getAllReferralUsers();
  },
  methods: {
    getAllReferralUsers() {
      userService.getAllReferralUsers(this.userId, this.search, this.page).then(response => {
        this.referralUsers = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      }).catch(() => {})
    },

    claimReferredUser(id, item) {
      let data = {
        first_name: item.first_name,
        last_name: item.last_name,
        email: item.email,
        is_claimed: !item.is_claimed,
        is_student: !!item.is_student,
        is_active: !!item.is_active,
      }
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            item.is_claimed = !item.is_claimed;
            userService
                .update(id, data)
                .then((response) => {
                  this.getAllReferralUsers();
                  this.$snotify.success("Update successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    }

  },
}
</script>
