<template>
    <v-app>
      <v-card class="p-8">
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-card-title>
                Managing Counsellor
              </v-card-title>
              <v-card-text>
                <div v-if="managers.length == 0">
                  No counsellor assigned to this user.
                </div>
                <v-carousel
                    v-if="managers.length != 0"
                    cycle
                    height="80"
                    progress-color="green"
                    hide-delimiter-background
                    show-arrows-on-hover
                >
                  <v-carousel-item
                      v-for="(manager, i) in managers"
                      :key="i"
                  >
                    <v-avatar
                        size="36px"
                    >
                      <!-- <img
                              v-if="manager.role"
                              alt="Avatar"
                              src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460"
                      > -->
                      <v-icon>fas fa-user</v-icon>
                    </v-avatar>
                    <strong class="text-h6 ml-2" v-text="manager.admin_name"></strong>
                    <br>
                    <span  class="subtitle-2 ml-2" v-text="manager.role"></span>
                  </v-carousel-item>
                </v-carousel>
              </v-card-text>
            </v-card>

            <v-card class="mt-3">
              <v-card-title>
                Next Follow Up
              </v-card-title>
              <v-card-text>
                <v-alert
                    border="left"
                    color="yellow accent-4"
                    colored-border
                    dense
                    elevation="2"
                    v-if="upcoming_appointment"
                >
                  <v-row>
                    <v-col cols="2">
                      <div class="d-flex flex-column flex align-items-center justify-content-center">
                        <div class="text-h2">
                          {{upcoming_appointment.formatted_date}}
                        </div>
                        {{upcoming_appointment.formatted_month}}
                      </div>

                    </v-col>
                    <v-col cols="10">
                      <div class="d-flex justify-content-around">
                        <div>
                          <v-icon color="yellow">
                            fas fa-circle
                          </v-icon>
                          <span class="subtitle-2">
                                        Upcoming
                                        </span>
                        </div>
                        <div>
                          <v-icon color="primary">
                            fas fa-clock
                          </v-icon>
                          <span class="subtitle-2">
                                        {{upcoming_appointment.formatted_time}}
                                        </span>
                        </div>
                        <div>
                          <v-icon color="primary">
                            fas fa-user
                          </v-icon>
                          <span class="subtitle-2">
                                        {{upcoming_appointment.admin_user_name}}
                                        </span>
                        </div>
                      </div>
                      <p class="p-2">
                        {{upcoming_appointment.note}}
                      </p>
                    </v-col>
                  </v-row>
                </v-alert>
                <div v-else>
                  There are no upcoming appointments.
                </div>
              </v-card-text>
            </v-card>

            <v-card class="mt-3" v-if="user && user.added_by">
              <v-card-title class="d-flex justify-content-end">
                <v-btn @click="assignAgent" class="text-white btn btn-primary" small>
                  <i class="fa fa-plus"></i> Assign Agent
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-card-title v-if="agent">

                  <div class="d-flex flex-column">
                    <div class="title">
                      <span class="text-green"> Agent assigned </span>
                    </div>
                    <div class="title ml-10">
                      <i class="fa fa-user"></i> {{agent.full_name}}
                    </div>
                    <div class="title ml-10">
                      <i class="fa fa-envelope"></i> {{agent.email}}
                    </div>
                  </div>
                </v-card-title>

                <v-card-subtitle v-if="user && user.agent_transfer">
                  <div class="d-flex flex-column">
                    <div class="title">
                      <span class="text-danger"> Agent transfer from </span>
                    </div>
                    <div class="title ml-10">
                      <i class="fa fa-user"></i> {{user.agent_transfer.full_name}}
                    </div>
                    <div class="title ml-10">
                      <i class="fa fa-envelope"></i> {{user.agent_transfer.email}}
                    </div>
                  </div>

                </v-card-subtitle>

                <div class="body" v-if="!agent">
                  There is no agent assigned to this  user.
                </div>
                <v-btn
                    v-if="agent"
                    color="red"
                    @click="removeAgent()"
                    class="text-white mt-2"
                    small
                >
                  <i class="text-white fa fa-trash-alt mr-2"></i> Remove agent
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card>
              <v-card-title>
                Visa Status
                <span v-if="application">
                            {{ '-' + application.academic_program }}
                        </span>
              </v-card-title>
              <v-card-text>
                <v-timeline
                    clipped
                    dense
                    v-if="application_histories"
                >
                  <v-timeline-item
                      class="mb-4"
                      color="grey"
                      icon-color="grey lighten-2"
                      small
                      v-for="(history, index) in application_histories" :key="index"
                  >
                    <v-row justify="space-between">
                      <v-col cols="8">
                        <v-chip
                            class="white--text ml-0"
                            :color="history.status_color"
                            label
                            small
                        >
                          {{history.application_status_title}}
                        </v-chip>
                      </v-col>
                      <v-col
                          class="text-right"
                          cols="4"
                      >
                        {{history.created_date}} {{history.created_time}}
                      </v-col>
                    </v-row>
                  </v-timeline-item>

                </v-timeline>
                <strong v-else>
                  Visa processing has not started yet.
                </strong>
              </v-card-text>
            </v-card>
          </v-col>

          <assign-agent @refresh="getUserAgent" ref="assign-agent"></assign-agent>

        </v-row>
      </v-card>
    </v-app>
</template>

<script>
import UserService from "@/services/user/UserService";
import AssignAgent from "./AssignAgent.vue";

const user = new UserService();

export default {
  name: "AccountSummary",
  components: {
    AssignAgent
  },
  props: ['managers', 'upcoming_appointment', 'agent', 'user', 'application', 'application_histories'],
  methods: {

    assignAgent(){
      this.$refs['assign-agent'].assignAgent(this.agent);
    },
    getUserAgent(){
      this.$emit('refresh');
    },
    removeAgent(){
      this.$confirm({
          message: `Are you sure?`,
          button: {
              no: 'No',
              yes: 'Yes'
          },

          callback: confirm => {
              if (confirm) {
                  user
                  .removeAgent(this.user.id)
                  .then(() => {
                      this.getUserAgent();
                      this.$snotify.success('Agent removed successfully.');
                  })
                  .catch((err) => {
                      this.$snotify.error('Oops, the agent could not be removed.');
                  })
                  .finally(() => {

                  });
              }
          }
      });
    },
  }
}
</script>
